import { Controller } from "@hotwired/stimulus"
import "leaflet"

export default class extends Controller {
  connect() {
    const { latitude, longitude, zoomLevel } = this.element.dataset;
    const locations = JSON.parse(this.element.dataset.locations);

    const map = L.map(this.element, {
      boxZoom: false,
      doubleClickZoom: false,
      dragging: true,
      scrollWheelZoom: true,
      zoomControl: true
    }).setView([latitude, longitude], zoomLevel);

    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`
    }).addTo(map);

    locations.forEach(location => {
      L.marker([location.latitude, location.longitude]).addTo(map);
    });
  }
}
